* {
  box-sizing: border-box;
  direction: rtl;
}

.trivia {
  width:100%;
  max-width: 500px;
  margin: 0 auto;
}

.trivia-section {
  display: none;

  &.active {
    display: block;
  }
}

// *** Question *** //
.trivia-question-label {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  font-size: 20px;
}
.trivia-question {
  margin-top: 5px;
  text-align: center;
  font-size: 24px;
}
.trivia-question-video {
  iframe {
    width: 100%;
  }
}
.trivia-question-image {
  margin-bottom: 20px;
  img {
    width: 100%;
  }
}

// *** Options *** //
.trivia-options {
  display: flex;
  flex-wrap: wrap;
}
.trivia-option {
  color: #000;
  border: 1px solid #fff;
  padding: 2px;
  cursor: pointer;
  width: 50%;
  text-align: center;
  font-size: 20px;
}
.trivia-option-container {
  height: 100%;
  padding: 40px 10px;
  border: 2px solid #4e5158;
  .trivia-option.active & {
    color: #fff;
    background-color: #4e5158;
  }
}

// *** Indicators *** //
.trivia-indicators {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0;
}

.trivia-indicator {
  width: 30px;
  height: 30px;
  background-color: #3b4356;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid #fff;
  font-size: 12px;
  border-radius: 50%;
  cursor: pointer;

  &.active {
    font-weight: bold;
    font-size: 14px;
  }
}

// *** Buttons *** //
.trivia-buttons {
  display: flex;
  justify-content: center;

}

.trivia-button {
  padding: 0 10px 10px;
  button {
    border: 0;
    color: #fff;
    //padding: 4px 10px;
    //font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// *** Results *** //
.trivia-results {
  text-align: center;
}
.trivia-results-title {
  font-size: 24px;
}
.trivia-results-subtitle {
  font-size: 20px;
}
.trivia-results-description {
  font-size: 16px;
}