.screen-tool {
  width: 100%;
  max-height: calc(100% - 57px);
  height: 100%;
  display: flex;
  flex-direction: column;
  //padding: 10px;
  margin: 0 auto 0;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  overflow: auto;
  position: relative;
  top: calc(50% - 28px);
  transform: translateY(-50%);
  padding: 0 5px;
  &.frame-4175 {
    padding: 0;
    background-color: #191919;
  }

  &.tool-coder {
    //border: 20px solid #d2d2d8;
  }

  &.fullScreen {
    margin-top: 0;
    max-height: 100%;
  }

  @media(min-height: 700px) {
    //justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.tool-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  //justify-content: center;
  width: 100%;
  overflow: auto;
  padding: 10px 10px 20px;
  flex-direction: column;
  background-size: 100% 100%;
  border-radius: 0;
  .frame-4121 & {
    padding-top: 60px !important;
  }
  .frame-3879 & {
    padding-top: 15vh;
  }
  .frame-3887 & {
    justify-content: center;
  }
  .frame-3513 & {
    padding-top: 60px !important;
  }
  .frame-4175 & {
    max-width: 500px;
    margin: 0 auto;
  }

  .frame-3885 & {
   padding: 0;
    background: rgb(2,198,254);
    background: linear-gradient(0deg, rgba(2,198,254,1) 0%, rgba(9,199,254,1) 10%, rgba(55,209,254,1) 20%, rgba(98,219,254,1) 30%, rgba(135,228,254,1) 40%, rgba(168,235,254,1) 50%, rgba(195,241,254,1) 60%, rgba(217,246,254,1) 70%, rgba(234,250,254,1) 80%, rgba(246,253,254,1) 90%, rgba(255,255,255,1) 100%);
  }
  .frame-4111 & {
    padding: 30px 0;
    .tool-content-item {
      padding: 0 40px 0;
      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar-track {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
  }

  .frame-2032 & {
    flex: initial;
    justify-content: flex-start !important;
   }

  .frame-3884 & {
    justify-content: flex-end;
  }

  .frame-2046 & {
    flex: inherit;
    max-width: 480px;
    margin: 0 auto;
    padding-top: 70px;
  }

  .frame-2033 & {
    padding-top: 40px;
  }

  .frame-2049 &, .frame-2033 & {
    background-position-y: 20px;
  }

  .frame-2031 & {
    background-position-y: 20px;
  }

  .frame-3856 &, .frame-3857 &, .frame-3859 & {
    justify-content: center;
  }

  .screen-tool-46 & {
    padding: 0;
    display: flex;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  .tool-coder & {
    border-radius: 20px;
    padding-top: 0;
    padding-bottom: 0;
    justify-content: center;

  }

  &.preview {
    min-height: 0;
  }

  &.fullScreen {
    padding: 0;
  }
}

.screen-tool-buttons-trigger {
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen-tool-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 200px;
    margin: 0 auto 15px;
  }
}

.tool-text-before, .tool-text-after {
  .screen-tool-63 & {
    text-align: center;
    padding: 0 5px;
  }

  .screen-tool-56 & {
    padding: 10px 10px 0;
  }
}

.screen-tool-62 {
  //height: 100%;
}