.contact-list {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}
.contact-list-contacts {
  background-color: #3b4356;
  z-index: 3;
  overflow: scroll;
  position: relative;
  top: 0;
  display: flex;
  border-radius: 15px;
  padding: 2rem 3rem;
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
}
.contact-list-list {
  width: 100%;
}
.contact-list-letters {
  width: 1.5rem;
  padding: 1rem;
  display: none;
}

/* single contact */
.contact-list-item {
  display: flex;
  align-items: center;
  padding: 0 0 0 15px;
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.contact-list-item-container {
  display: flex;
  align-items: center;
  flex: auto;
  padding: 5px 0;
}
.contact-list-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contact-list-item-name {
  font-size: 20px;
  margin: 0;
  color:#fff;
}
.contact-list-item-phone {
  font-size: 15px;
  margin: 0;
  color:#fff;
}
.contact-list-item-link {
  display: block;
  width: 100%;
  font-size: 20px;
  text-decoration: none;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
