.coder-container {
  width: 100%;
  //height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //min-height: 100%;
  max-width: 400px;
  padding: 30px 10px;
  position: relative;
  border-radius: 10px;
  margin: 0 auto;
  background-color: #1e1d3d;

  .input-container {
    width: 100%;
    //height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0 0;
  }

  .hasTextBefore & {
    min-height: auto;
  }
}


.coder-top-title {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0;
}

.coder-user-password {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  width: 100%;

  &.hebrew {
    flex-direction: row;
  }

}

.single-user-password {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 30px;
  border-bottom: 2px solid #fff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  background-color: transparent !important;
}

.coder-container .single-user-password {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  background-color: transparent;
}

.single-user-password-color-value {
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.coder-error-text {
  font-weight: bold;
  font-size: 18px;
  width: 100%;
  text-align: center;
  height: 30px;
  visibility: hidden;

  &.active {
    visibility: visible;
  }

  &.fade {
    animation: linear infinite coderErrorFade 1s;
    @keyframes coderErrorFade {
      0%, 100% {
        opacity: 0
      }
      50% {
        opacity: 1;
      }
    }
  }
}

.coder-bottom-buttons {
  display: flex;
  width: 70%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //margin-bottom: 30px;
}

.coder-bottom-button {
  object-fit: contain;
  user-select: none;

  &:hover {
    filter: drop-shadow(0 0 1px rgba(black, 0.5));
    cursor: pointer;
  }

  &:active {
    filter: drop-shadow(0 0 2px rgba(black, 0.5));
    cursor: pointer;
  }

  .disabled & {
    &:hover {
      filter: initial;
      cursor: default;
    }

    &:active {
      filter: initial;
      cursor: default;
    }
  }
}

.coder-submit-button {
  &:hover {
    filter: brightness(60%);
    cursor: pointer;
  }

  &:active {
    filter: brightness(40%);
    cursor: pointer;
  }

  .disabled & {
    &:hover {
      filter: initial;
      cursor: default;
    }

    &:active {
      filter: initial;
      cursor: default;
    }
  }
}
