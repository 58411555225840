.tool-choose-item {
  width: 100%;

  &.grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .frame-3879 & {
    margin-top: 40px;
  }
}

.tool-choose-item-wrong {
  text-align: center;
  color: #fff;
  padding: 0 30px;

  p {
    font-size: 20px;
  }
}

.tool-choose-item-section {
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 0;
  margin: 10px 0;
  &.active {
    .tool-choose-item-image {
      img {
        border: 5px solid #fff;
      }
    }

  }

  .grid & {
    margin: 0;
  }

  .frame-3884 &, .frame-3879 & {
    width: 25%;
    padding: 0;
  }
}

.tool-choose-item-image {
  position: absolute;
  right: 10px;

  .frame-3879 & {
    position: static;
    padding: 10px;
  }

  .frame-3884 & {
    width: 100%;
    text-align: center;
    position: relative;

    img {
      width: 100%;
    }
  }
}

.tool-choose-item-wrong-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.tool-choose-item-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  flex: 1;
  font-size: 20px;

  p {
    text-align: center;
    margin: 0;
  }

  .grid & {
    box-shadow: 0 1px 2px #000;
  }

  .active & {
    background-color: #6c757d;
    color: #fff;
  }
}

.screen-tool-78 {

}

.tool-text-before {
  .screen-tool-78 & {
    text-align: center;
  }
  .frame-2033 & {
    background-color: #d3ae3d;
    margin-top: 0;
    margin-bottom: 0;
    position: relative;
    & + div {
      background-color: #d3ae3d;
      padding-top: 20px;
    }
  }


  &:before {
    .frame-2033 & {
      position: absolute;
      width: 200px;
      background-color: #d3ae3d;
      top: -30px;
      right: 0;
      font-size: 18px;
      padding: 6px 20px;
      border-top-left-radius: 5px;
      content: "שליחת חשודים למעצר";
    }
  }
}
