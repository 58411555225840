.tool-content {
  max-width: 500px;
  margin: 0 auto;
  //padding: 30px 0;
  //background-color: #d3ae3d;
  position: relative;
  //border-top-left-radius: 5px;
  //border-bottom-left-radius: 5px;
  //border-bottom-right-radius: 5px;
  //border: 1px solid #000;
  //min-height: 100%;

  .frame-3887 & {
    margin: 0 auto;
    position: relative;
    max-height: 350px;
    overflow: auto;
    padding: 0 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }
  .frame-2049 &, .frame-2031 & {
    background-color: #d3ae3d;
    margin-top: 50px;
    //max-height: 80%;
    //overflow: auto;
  }
  &:before {
    position: absolute;
    width: 200px;
    background-color: #d3ae3d;
    top: -30px;
    font-size: 18px;
    padding: 6px 20px;
    border-top-left-radius: 5px;
    .frame-2049 & {
      content:"שליחת חשודים למעצר";
    }
    .frame-2031 & {
      content:"תוצאות חקירה";
    }
  }
  .frame-4111 & {
    padding-top: 0;
    color:#fff;
  }
  .frame-4115 & {
    color:#fff;
  }
  .frame-4119 & {
    color:#fff;
  }

  .frame-4170 & {
    padding:0;
  }

  .before {
    position: absolute;
    top: -25px;
    right: -.1rem;
    padding: 3px 20px 0 40px;
    font-size: 18px;
    background-color: #d3ae3d;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
  }
  p.tool-content-text {
    font-size: 1.8rem;
    white-space: pre-wrap;
    padding: 10px 17vw;
    margin-top: 0;

    .frame-4111 & {
      padding: 10px 10vw;
    }
    p {
      margin-top: 0;
      background-color: #42210b;
      .frame-2049 & {
        background-color: transparent !important;
      }
    }
  }
  &.tool-content-tabs {
    p.tool-content-text {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.email-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 30px;
  input {
    width: 300px;
    background-color:#fff;
    border-radius: 30px;
    margin: 0 auto;
    outline: none;
    height: 40px;
    text-align: center;
    font-size: 15px;
    border: 0;
    margin-bottom: 10px;
  }
}