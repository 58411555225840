.rotary-wrapper{
  overflow: visible;
  margin: 10px 20px;
  display: flex;
  user-select: none;
  cursor:pointer;
  -webkit-tap-highlight-color: transparent;
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  &.inside {
    margin: 10px;
  }

  .rotary{
    height: 80%;
    width: 80%;
    margin: 10%;
    border-radius: 50%;
    border-style: solid;
    //background-color: transparent;
    display: flex;
    transition-duration: 0.4s;

    .rotary-line{

      position: absolute;
      z-index: 30;
      margin: auto;
    }

    .rotary-dot{
      position: absolute;
      z-index: 30;
      margin: auto;
    }

    .rotary-arrow{
      position: absolute;
      z-index: 30;
      margin: auto;
      right: calc(50% - 10px);
      //top: 25%;
      width: 20px;
      text-align: center;
      &.out{
        top: 0;
      }

      //&::after{
      //  content: " ";
      //  display: block;
      //  width: 30px;
      //  height: 30px;
      //  position: relative;
      //  right: calc(50% - 22.5px);
      //  top: -10px;
      //  transform: rotate(45deg);
      //  border-width: 15px 0  0 15px ;
      //  border-style: solid;
      //}
    }
  }

  .rotary-numbers{
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    &.inside{
      height: 100%;
      width: 100%;
    }

    .rotary-number{
      position: absolute;
      height: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: 800px;
    }
  }
}