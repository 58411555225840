.screen-riddle {
  width:100%;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  &.fullscreen {
    padding-top: 0;
  }
}
.riddle-header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 0;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #000;
  z-index: 10;
  background-color: #fff;
  h3 {
    font-weight: normal;
    margin: 0;
    text-align: center;
    font-size: 20px;
  }
}

.riddle-next-button {
  margin: 10px auto 10px;
  //padding :
  //width: 150px;
  display: block;
  cursor: pointer;
}