@import "../../../assets/css/variables/variables";
.tool-truth-or-lie {
  background-repeat: no-repeat;
  background-size: cover;
  padding: $gutter-sm;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex: 1;
}

.tool-truth-or-lie-container {

  width: 500px;
}