.audio-list {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  margin: 0 auto;
  position: relative;

  .frame-3888 & {
    padding-bottom: 70px;
    @media (max-width: 360px) {
      padding-bottom: 40px;
    }
  }
}

.audio-list-contacts {
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: scroll;
  //position: absolute;
  top: 0;
  display: flex;
  border-radius: 15px;

  .frame-4202 & {
    height: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }
}

.audio-list-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &.view-grid {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    align-content: baseline;
  }

  .iTunes & {
    padding: 0;
    border-top: 2px solid #6c757d;
  }

  @media (min-width: 375px) {
    padding: 60px 0;
    .frame-3888 & {
      padding: 40px 0;
    }
  }
}

.audio-list-bottom {
  background-color: #dcdbda;
}

.audio-list-player {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  position: relative;

  h2, h5 {
    text-align: center;
    margin: 0;
    font-weight: normal;

    &.hidden {
      opacity: 0;
    }
  }

  h2 {
    font-size: 21px;
  }

  h5 {
    font-size: 13px;
  }
}

.audio-list-icons {
  display: flex;
  align-items: center;
  .frame-3512 & {
    width: 100px;
  }
}

.audio-list-play {
  position: absolute;
  left: 15px;
  @media (max-width: 360px) {
    top: 5px;
    left: 5px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

.audio-list-tabs {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 30px;
  padding-top: 20px;
  border-top: 2px solid #6c757d;
}

/* single audio item */
.audio-list-item {
  display: flex;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;

  .iTunes & {
    border-bottom: 2px solid #6c757d;
    padding: 5px 0
  }

  .view-grid & {
    justify-content: flex-start;
    margin-bottom: 15px;
    position: relative;
    width: 50%;
    @media (max-width: 360px) {
      padding: 0 10px;
    }
    .frame-3888 & {
      padding: 0 10px;
    }
  }
}

.audio-list-image {
  margin-right: 10px;
  .frame-3512 & {
    width: 100%;
  }
  .view-grid & {
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: 22px;
  }
}

.audio-list-item-container {
  display: flex;
  flex: auto;
  padding: 5px 0;
  position: relative;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  .view-grid & {
    padding: 40px 0;
  }

  .frame-3888 & {
    height: 120px;
  }
}

.audio-list-item-details {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: left;
  padding-left: 10px;
  & > div {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    .audio-list-item-title {padding-right:3px}
  }
  .iTunes & {
    padding-left: 10px;
  }
}

.audio-list-play-icon {
  .view-grid & {
    position: absolute;
    bottom: -15px;
    left: -10px;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.audio-list-item-name {
  font-size: 18px;
  margin: 0;
  color: #1e1d3d;
  display: block;
  font-weight: bold;

  .iTunes & {
    color: #e5e6e8;
    font-size: 13px;
    font-weight: normal;
  }
}

.audio-list-item-title {
  .iTunes & {
    color: #fff;
    font-weight: normal;
  }
}

.audio-list-item-link {
  display: block;
  width: 100%;
  font-size: 20px;
  text-decoration: none;
  margin-right: 15px;
  .view-grid & {
    margin: 0;
  }
}
